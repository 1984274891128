import './ExploreContainer.css';

interface ContainerProps { }

const ExploreContainer: React.FC<ContainerProps> = () => {
  return (
    <>
    <div className="container">
      <strong>Welcome</strong>
      <p>Follow me on: <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/yabunchanquin/">Instagram</a></p>
      <p>Links <a target="_blank" rel="noopener noreferrer" href="/links">Links Page</a></p>
    </div>
    </>
  );
};

export default ExploreContainer;
